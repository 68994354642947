// Normalize string
import { getType } from '../types'

export function normalizeString(word) {
  let string = word.replace(/^\s+|\s+$/g, '')
  const mapAcentsHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  }

  Object.entries(mapAcentsHex).map(([letter, regularExpression]) => {
    string = string.replace(regularExpression, letter)
    return true
  })

  string = string.split(' ').join('_').toLowerCase()

  return string
}

export const onlyNumbers = (string) => {
  if (getType(string) !== 'string') {
    throw new Error('Informed parameter is not a string type')
  }

  return string.replace(/\D/g, '')
}
export const normalizeNumber = (string) => Number(string?.replace(/[^\d,-]/g, '').replace(',', '.'))

export const insertCNPJMask = (string) =>
  string.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

export function replaceEmptyCharToWhitespace(string) {
  return string.replace(/\s+/g, ' ')
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
