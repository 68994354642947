import React, { useState, useContext, useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import './index.scss';
import { Checkbox } from '../../components/Argo-ui';
import { DataContext } from '../../data/DataProvider';
import { getProtecionais } from '../../services/base-service';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import { normalizeString } from '../../utils/string';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import MainFooter from '../../components/main-footer/main-footer';
import DefaultLayout from '../../components/layouts/default-layout';

const block = 'page-sistemas-protecionais';
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    protectionSystems,
    setProtectionSystems,
    setModal,
    originalQuotation,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [valid, setValid] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.avaliacaoRisco)
      return navigate('/avaliacao-de-risco');
  }, [quotation, quotation?.avaliacaoRisco]);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };
  const save = () => {
    return changeValorParcela(
      quotation,
      responseMotor.response,
      setModal,
      setDisableSave,
      setOriginalQuotation
    );
  };

  const inputChangeHandler = useCallback(
    (val, id) => {
      if (quotation?.sistemaProtecional) {
        if (val && id) {
          trackCustomEvent({
            category: 'Adiciona sistema protecional',
            action: 'Click',
            label: id,
          });
          setQuotation({
            ...quotation,
            sistemaProtecional: {
              protecoes: [
                ...quotation?.sistemaProtecional?.protecoes.filter(
                  (filter) => filter !== id
                ),
                id,
              ],
            },
            isProposal: false,
          });
        } else {
          trackCustomEvent({
            category: 'Remove sistema protecional',
            action: 'Click',
            label: id,
          });
          setQuotation({
            ...quotation,
            sistemaProtecional: {
              protecoes: [
                ...quotation?.sistemaProtecional?.protecoes.filter(
                  (filter) => filter !== id
                ),
              ],
            },
            isProposal: false,
          });
        }
      } else {
        setQuotation({
          ...quotation,
          sistemaProtecional: {
            protecoes: [id],
          },
          isProposal: false,
        });
      }
      if (id === 2) {
        setValid(!val);
      }
    },
    [quotation, setQuotation]
  );

  useEffect(() => {
    async function fetchData() {
      if (!protectionSystems) {
        const listProtecionais = await getProtecionais();
        setProtectionSystems(listProtecionais);
      }
    }
    fetchData();
  }, [protectionSystems, setProtectionSystems]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/avaliacao-de-risco')}
          editMode={!!quotation?.propostaId}
          title="Sistemas protecionais"
          description={
            <span>
              Os sistemas protecionais da empresa influenciam no cálculo do
              prêmio e na aceitação da proposta. <br />O valor será atualizado
              no box de preço (ao lado) e você será informado caso haja alguma
              restrição.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
          ]}
          fixed
          hide={quotation?.personalizado}
        />
        <div className={`container--large ${block}--fixed`}>
          <div className={`${block}__wrapper`}>
            <div className={`${block}--row`}>
              <div className={`${block}--item-full`}>
                <span className={`${block}__label`}>
                  Marque os protecionais existentes na empresa:
                </span>
              </div>
            </div>
            <div className={`${block}--row`}>
              {protectionSystems &&
                protectionSystems.map((row) => {
                  return (
                    <div key={row.value} className={`${block}--item`}>
                      <Checkbox
                        id={row.value}
                        name={normalizeString(row.label)}
                        label={row.label}
                        labelSize={`${11 / 13}rem`}
                        onChange={(item) => inputChangeHandler(item, row.value)}
                        large
                        checked={
                          quotation &&
                          quotation?.sistemaProtecional?.protecoes.indexOf(
                            row.value
                          ) > -1
                            ? true
                            : false
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`container--large`}>
          {isEditValid(quotation) && (
            <div className="action_buttons">
              <Button
                className="button_cta"
                type="button"
                theme={'secondary'}
                onClick={cancel}
                disabled={disableSave}
              >
                Cancelar edição
              </Button>
              <Button
                className={`button_cta button--margin-left`}
                theme={'tertiary'}
                onClick={save}
                disabled={valid || disableSave}
              >
                Salvar edição
              </Button>
            </div>
          )}
          {!quotation?.propostaId && (
            <Button
              className="button_cta"
              onClick={() => !valid && navigate('/sinistralidade')}
              disabled={valid}
            >
              Avançar
            </Button>
          )}
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
